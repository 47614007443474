import { Link } from "react-router-dom";
import { AuthContext } from "../context/authcontext";
import { useContext } from "react";
import { FiEdit, FiDelete } from "react-icons/fi";
import { RiDeleteBin5Fill } from "react-icons/ri";

const NewsComponents = ({ news, handleDelete }) => {
  const { user } = useContext(AuthContext);
  return (
    <div className="flex duration-300   hover:bg-[#abcd6c] rounded-md justify-between shadow-lg px-1 items-center py-4 ">
      <Link
        to={`/news-details/${news.news_id}`}
        className="w-full font-rabar-3 text-xl text-gray-800"
      >
        {news.news_title}
      </Link>
      <div className="flex items-center gap-4">
        <Link to={`/news-edit/${news.news_id}`} className="">
          <FiEdit className=" duration-300  text-2xl  " />
        </Link>
        {user.user.role == "admin" && (
          <button
            className=" text-red-600"
            onClick={() => handleDelete(news.news_id)}
          >
            <RiDeleteBin5Fill className=" duration-300  text-2xl text-red-600 " />
          </button>
        )}
      </div>
    </div>
  );
};

export default NewsComponents;
