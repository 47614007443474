import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authcontext";

const LoginComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://api.kbclive.net/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        const { error } = await response.json();
        setError(true);
        console.error("Error:", error);
        return;
      }

      const data = await response.json();
      localStorage.setItem("user", JSON.stringify(data));
      setError(false);
      dispatch({ type: "LOGIN", payload: data });
      navigate("/");
    } catch (err) {
      console.error("Error:", err);
    }
  };

  return (
    <div className=" bg-[#abcd6c] mt-72 max-w-md mx-auto">
      <form className=" w-60 py-20  mx-auto" onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className=" font-rabar-2 text-2xl" htmlFor="email">
            ئیمەیڵ:
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label className=" font-rabar-2 text-2xl" htmlFor="password">
            وشەی تێپەر:
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <button
          className="duration-300 bg-white font-rabar-3 text-2xl text-gray-900 w-full mt-10 py-4 hover:bg-black hover:text-white"
          type="submit"
        >
          چونەژورەوە
        </button>
        {error && (
          <h1 className=" bg-red-600 mt-3 text-white px-2 py-2">
            ئیمێڵ یان وشەی نهێنیت هەڵەیە
          </h1>
        )}
      </form>
    </div>
  );
};

export default LoginComponent;
