import { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Navbar from "./components/nav";
import { AuthContext } from "./context/authcontext";
import AddNews from "./pages/addnews";

import Home from "./pages/home";
import LoginPage from "./pages/login";
import NewsEdit from "./pages/news-edit";
import NewsDetails from "./pages/newsdetails";
import LastNews from "./pages/lastnews";

function App() {
  const { user } = useContext(AuthContext);

  return (
    <div className="">
      <BrowserRouter>
        <div>{user && <Navbar />}</div>

        <Routes>
          <Route
            path="/"
            element={user ? <LastNews /> : <Navigate to="/login" />}
          />

          <Route
            path="/all-news"
            element={user ? <Home /> : <Navigate to="/login" />}
          />

          <Route
            path="/addnews"
            element={user ? <AddNews /> : <Navigate to="/login" />}
          />
          <Route
            path="/news-details/:news_id"
            element={user ? <NewsDetails /> : <Navigate to="/login" />}
          />
          <Route
            path="/news-edit/:news_id"
            element={user ? <NewsEdit /> : <Navigate to="/login" />}
          />

          <Route
            path="/login"
            element={!user ? <LoginPage /> : <Navigate to="/" />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
