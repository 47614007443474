import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../hooks/fetch";
import ReactPaginate from "react-paginate";
import NewsComponents from "../components/news-compenent";
import "./paginateStyle.css";
const LastNews = () => {
  const [trem, setTrem] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const naviate = useNavigate;
  const categoryUrl = "https://api.kbclive.net/category/";
  const [filtter, setFiltter] = useState("all");
  const [all, setAll] = useState(true);
  const handleClick = (categoryName) => {
    setFiltter(categoryName);
    setAll(false);
  };

  const {
    getData: getCategory,
    data: category,
    isloading: isCategory,
    error: isErrorCategory,
  } = useFetch();
  const newsUrl = "https://api.kbclive.net/last-news/";
  const { getData, data: news, isloading, error } = useFetch();

  useEffect(() => {
    getCategory(categoryUrl);
    getData(newsUrl);
  }, []);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const filteredNews = all
    ? news &&
      news.filter(
        (newsItem) =>
          newsItem.news_title.includes(trem) ||
          newsItem.news_id.toString().includes(trem.toString())
      )
    : news &&
      news
        .filter((newss) => filtter === newss.category_id)
        .filter((news) => news.news_title.includes(trem));

  const displayedNews =
    filteredNews &&
    filteredNews.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  const handleDelete = (newsId) => {
    if (window.confirm("Are you sure you want to delete this news?")) {
      // make delete request to server
      fetch(`https://api.kbclive.net/news/${newsId}`, {
        method: "DELETE",
      })
        .then((res) => {
          if (res.ok) {
            console.log(`News with ID ${newsId} has been deleted.`);
            // reload the page after successful deletion
            window.location.reload();
          } else {
            throw new Error(`Failed to delete news with ID ${newsId}.`);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <div className="grid  grid-cols-[200px_minmax(900px,_1fr)_100px]">
      <div className="pt-10 bg-[#abcd6c]  flex flex-col justify-between">
        {isCategory ? (
          <div>Loading...</div>
        ) : isErrorCategory ? (
          <div>Error: {isErrorCategory.message}</div>
        ) : (
          <ul className="flex flex-col gap-7">
            <button
              onClick={() => {
                setFiltter("all");
                setAll(true);
              }}
              className="nav-button"
            >
              سەرجەم بابەتەکان
            </button>
            {category &&
              category.map((cate) => (
                <div key={cate.catigory_id}>
                  <button
                    onClick={() => handleClick(cate.category_id)}
                    className="nav-button"
                  >
                    {cate.category_name}
                  </button>
                </div>
              ))}
          </ul>
        )}
      </div>
      <div className=" px-10">
        <div className=" flex justify-center mb-5"></div>
        <form className="mb-5">
          <input
            className=" bg-[#abcd6c] placeholder:text-gray-200 rounded-md text-white"
            placeholder="گەران..."
            type="text"
            required
            onChange={(e) => setTrem(e.target.value)}
          />
        </form>
        <div>
          <hr />
          <div className="flex gap-3 flex-col">
            {displayedNews &&
              displayedNews.map((newss) => (
                <NewsComponents
                  handleDelete={handleDelete}
                  key={newss?.news_id}
                  news={newss}
                />
              ))}
          </div>
          <ReactPaginate
            previousLabel={"پێشتر"}
            nextLabel={"دواتر"}
            pageCount={Math.ceil(
              filteredNews && filteredNews.length / itemsPerPage
            )}
            onPageChange={handlePageChange}
            containerClassName={"paginate"}
            previousLinkClassName="prev"
            nextLinkClassName="nextlink"
            disabledClassName="disabled"
            activeLinkClassName="activelink"
          />
        </div>
      </div>
    </div>
  );
};

export default LastNews;
