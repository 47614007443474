import { Editor } from "@tinymce/tinymce-react";
import React, { useState, useRef, useEffect } from "react";
import useFetch from "../hooks/fetch";
import { useNavigate } from "react-router-dom";
function AddNews() {
  const navigate = useNavigate();
  const [news_title, setTitle] = useState("");
  const [news_description, setDescription] = useState("");
  const [news_auther, setAuthor] = useState("");
  const [image, setImage] = useState("2");
  const [news_link, setLink] = useState("");
  const [news_date, setDate] = useState(new Date());
  const [category_id, setCategory] = useState("");
  const [program_kind, setProgram] = useState("");

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      setDescription(editorRef.current.getContent());
    }
  };

  const { getData, data } = useFetch();
  useEffect(() => {
    getData("https://api.kbclive.net/category/");
    console.log(data);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("news_title", news_title);
    formData.append("news_description", news_description);
    formData.append("news_auther", news_auther);
    formData.append("news_date", news_date);
    formData.append("category_id", category_id);
    formData.append("news_link", news_link);
    formData.append("program_kind", program_kind);

    formData.append("image", image);

    fetch("https://api.kbclive.net/news/", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    navigate("/");
  };

  return (
    <form className=" max-w-6xl mx-auto px-4 py-10" onSubmit={handleSubmit}>
      <div className=" flex flex-col gap-10">
        <label className="">
          <p className="mb-2 font-rabar-1 text-2xl">سەردێر :</p>
          <input
            type="text"
            value={news_title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </label>
        <label className="flex gap-10 items-center">
          <p className="mb-2 font-rabar-1 text-2xl">جۆری هەواڵ :</p>
          <select
            value={category_id}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
          >
            <option value="">Select an option</option>
            {data &&
              data.map((category) => (
                <option key={category.category_id} value={category.category_id}>
                  {category.category_name}
                </option>
              ))}
          </select>
        </label>
        <label className=" hidden">
          News Description:
          <textarea
            value={news_description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </label>
        <label>
          <p className="mb-2 font-rabar-1 text-2xl">ناوەرۆک :</p>
          <div>
            <Editor
              className=" bg-gray-200"
              onInit={(evt, editor) => (editorRef.current = editor)}
              init={{
                height: 500,
                menubar: false,
                plugins: [],
                toolbar:
                  "undo redo  " +
                  "bold italic backcolor forecolor fontsizes | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
          </div>
        </label>
        <label className="flex gap-10 items-center">
          <p className="mb-2 font-rabar-1 text-2xl">جۆری بەرنامەکە :</p>
          <select
            value={program_kind}
            onChange={(e) => {
              setProgram(e.target.value);
            }}
            defaultValue="بەرنامە نیە"
          >
            <option value="">Select an option</option>
            <option value="بەرنامەی لە سۆشیال میدیا">بەرنامە نیە</option>
            <option value="بەرنامەی ژیری تەندروستی">
              بەرنامەی ژیری تەندروستی
            </option>
            <option value="بەرنامەی لە سۆشیال میدیاوە">
              بەرنامەی لە سۆشیال میدیاوە
            </option>
            <option value="بەرنامەی شیکاری وەرزشی">
              بەرنامەی شیکاری وەرزشی
            </option>
            <option value="بەرنامەی گوتار">بەرنامەی گوتار</option>
          </select>
        </label>
        <label>
          <p className="mb-2 font-rabar-1 text-2xl">نوسەر :</p>
          <input
            type="text"
            value={news_auther}
            onChange={(e) => setAuthor(e.target.value)}
          />
        </label>
        <label>
          <p className="mb-2 font-rabar-1 text-2xl">وێنە :</p>
          <input type="file" onChange={(e) => setImage(e.target.files[0])} />
        </label>
        <label>
          <p className="mb-2 font-rabar-1 text-2xl">لینکی ڤیدیۆ :</p>
          <input
            type="text"
            value={news_link}
            onChange={(e) => setLink(e.target.value)}
          />
        </label>
        <label>
          <p className="mb-2 font-rabar-1 text-2xl">کات :</p>
          <input
            type="date"
            value={news_date}
            onChange={(e) => setDate(e.target.value)}
          />
        </label>

        <button
          className="py-2 text-2xl font-rabar-3 duration-200 bg-[#abcd6c] hover:bg-[#7c9847]  w-full"
          onClick={log}
          type="submit"
        >
          زیادکردن
        </button>
      </div>
    </form>
  );
}

export default AddNews;
