import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/authcontext";
import { BsFillPersonCheckFill } from "react-icons/bs";

const Navbar = () => {
  const { dispatch, user } = useContext(AuthContext);
  const handleSubmit = async (e) => {
    e.preventDefault();
    localStorage.removeItem("user");
    dispatch({ type: "LOGOUT" });
  };
  return (
    <nav className="flex  py-5  bg-[#abcd6c] justify-between ">
      <div className=" flex gap-10 pr-52 items-center">
        <Link
          to="/addnews"
          className="text-[#2a301e] font-rabar-1 text-2xl duration-200 bg-white px-5 py-2 rounded-md hover:text-green-900 "
        >
          زیاد کردن
        </Link>
        <Link
          to="/"
          className="text-gray-800 font-rabar-1 text-2xl duration-200 hover:text-white"
        >
          پەرەی سەرەکی
        </Link>
        <Link
          to="/all-news"
          className="text-gray-800 font-rabar-1 text-2xl duration-200 hover:text-white"
        >
          سەرجەم هەواڵەکان
        </Link>
      </div>
      <div className=" flex gap-8 pl-32 items-center">
        {user && (
          <div className=" flex gap-2 items-center">
            <h1 className=" font-rabar-4 text-2xl text-gray-800 rounded-md">
              {user.user.name}
            </h1>
            <BsFillPersonCheckFill className=" duration-300  text-gray-100 text-2xl  " />
          </div>
        )}
        {user && (
          <form onSubmit={handleSubmit} className="handlesubmet">
            <button
              className=" bg-red-700 font-rabar-3 text-xl text-white rounded-md duration-300 hover:bg-red-900 
             px-5 py-2 "
            >
              چونەدەرەوە
            </button>
          </form>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
