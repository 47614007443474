import { useEffect } from "react";
import useFetch from "../hooks/fetch";
import YouTube from "react-youtube";

import { useParams } from "react-router-dom";

const NewsDetails = () => {
  const { news_id } = useParams();
  const newsUrl = `https://api.kbclive.net/news/${news_id}`;
  const { getData: getNews, data: news } = useFetch();
  useEffect(() => {
    getNews(newsUrl);
  }, [newsUrl]);

  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div
      className=" max-w-7xl mx-auto items-center py-10
    "
    >
      <img
        className=" object-cover object-center mb-5"
        src={`https://api.kbclive.net/uploads/${news?.image}`}
        alt={news?.news_image}
      />
      <span className="font-rabar-2 text-xl mb-3">
        {news?.news_date?.substring(0, 10)}
      </span>
      {"| "}
      <span
        className=" font-rabar-2 text-xl mb-3
      "
      >
        {news?.news_auther}
      </span>

      <h1 className="font-rabar-3 text-3xl mb-3">{news?.news_title}</h1>

      {news?.category_id === 4 && (
        <h1 className="font-rabar-3 text-3xl mb-3">{news?.program_kind}</h1>
      )}

      <div
        className=" max-w-4xl  text-primary leading-relaxed mb-8 s:text-base md:w-full"
        dangerouslySetInnerHTML={{
          __html: news?.news_description,
        }}
      />
      <div>
        {news?.news_link && (
          <YouTube
            videoId={news?.news_link.substring(
              news?.news_link.lastIndexOf("/") + 1
            )}
            opts={opts}
          />
        )}
      </div>
    </div>
  );
};

export default NewsDetails;
